import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import MEDIA from '../../helpers/mediaTemplates';

const applyFontSize = size => css`
  font-size: ${() => {
    switch (size) {
      case 'x-large':
        return '6.5rem';
      case 'large':
        return '3.2rem';
      case 'medium':
        return '2rem';
      case 'small':
        return '1.5rem';
      default:
        return '2rem';
    }
  }};

  ${MEDIA.TABLET`
    font-size: ${() => {
      switch (size) {
        case 'x-large':
          return '4rem';
        case 'large':
          return '2.4rem';
        case 'medium':
          return '1.8rem';
        case 'small':
          return '1.2rem';
        default:
          return '1rem';
      }
    }};
  `};
`;

const getLetterSpacingForType = type => {
  switch (type) {
    case 'h1':
      return '-0.04em';
    case 'h2':
      return '-0.03em';
    case 'h3':
      return '-0.02em';
    case 'span':
      return '0.5px';
    default:
      return '';
  }
};

/*
const StyledText = styled.span`
  display: block;
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'x-large':
        return '4.5rem';
      case 'large':
        return '3.2rem';
      default:
        return '2rem';
    }
  }};
  line-height: 1.2;

  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'large':
          return '2.6rem';
        default:
          return '2rem';
      }
    }};
  `};
`;
*/

const StyledText = styled.span`
  display: block;
  font-weight: ${({ weight }) => weight};
  ${props => applyFontSize(props.size)};
  ${({ italic }) => italic && 'font-style: italic'};
  line-height: 1.2;
  letter-spacing: ${props => getLetterSpacingForType(props.as)};
  ${props => props.as === 'span' && 'text-transform: uppercase'};
  text-align: ${props => props.align};
  color: ${props => props.color};
  margin-bottom: ${props => props.paddingBottom * 8}px;
`;

const Text = ({
  children,
  as = 'p',
  color = 'black',
  paddingBottom = 1,
  align = 'left',
  ...rest
}) => {
  return (
    <StyledText
      as={as}
      color={color}
      paddingBottom={paddingBottom}
      align={align}
      {...rest}
    >
      {children}
    </StyledText>
  );
};

Text.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span'])
    .isRequired,
  size: PropTypes.oneOf(['x-large', 'large', 'medium', 'small']),
  weight: PropTypes.oneOf(['regular', 'bold']),
  italic: PropTypes.bool,
  paddingBottom: PropTypes.number,
  color: PropTypes.string,
  align: PropTypes.string,
};

export default Text;
