import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from '../Container';
import Text from '../Text';
import Icon from '../Icon';

import config from '../../config';

const RootContainer = styled.footer`
  width: 100%;
  height: ${config.sizes.footer}px;
  background-color: ${config.colors.primary};

  > div {
    height: 100%;
  }
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > ul {
    display: flex;
    flex-direction: row;

    li:not(:last-child) {
      margin-right: 5px;
    }
  }
`;

const IconListItem = styled.li`
  height: 25px;
  width: 25px;

  svg {
    fill: white;
  }
`;

const SocialLink = ({ iconName, link }) => (
  <IconListItem>
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Icon name={iconName} />
    </a>
  </IconListItem>
);

SocialLink.propTypes = {
  iconName: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

const Footer = () => (
  <RootContainer>
    <Container>
      <Content>
        <Text as="span" size="small" color="white">
          Copyright © 1997 - {new Date().getFullYear()} Therapeutic Kneads, LLC.
        </Text>
        <ul>
          <SocialLink
            iconName="facebook"
            link="https://www.facebook.com/TherapeuticKneadsRMT"
          />
          <SocialLink
            iconName="instagram"
            link="https://www.instagram.com/therapeutic_kneads"
          />
        </ul>
      </Content>
    </Container>
  </RootContainer>
);

export default Footer;
